import React, { useState, useRef, useEffect } from 'react';
import TriggerOnCenterVisible from './TriggerOnCenterVisible';
import BullChartImage from './BullChartImage';
import BullChartAnnotations from './BullChartAnnotations';
import './bull-chart-image.scss';

const BullChartSection = () => {
  const [ visible, setVisible ] = useState(false);
  const [ annotations, setAnnotations ] = useState(false);
  const [ charts, setCharts ] = useState(false);
  const timeout1 = useRef(null);
  const timeout2 = useRef(null);
  const start = () => {
    setVisible(true);
  };
  useEffect(() => {
    if (visible) {
      timeout1.current = setTimeout(() => {
        setCharts(true);
      }, 1000);
      timeout2.current = setTimeout(() => {
        setAnnotations(true);
      }, 2000);
    }
    return () => {
      clearTimeout(timeout1.current);
      clearTimeout(timeout2.current);
    };
  }, [ visible ]);
  const className = visible ? 'image bull-chart-image active' :
    'image bull-chart-image';
  return (
    <div className={ className } id="bull-chart-image">
      <div className="image-wrapper">
        <TriggerOnCenterVisible onVisible={ start }>
          <BullChartImage className={ charts ? 'active' : ''}/>
          <BullChartAnnotations className={ annotations ? 'active' : ''}/>
        </TriggerOnCenterVisible>
      </div>
    </div>
  );
};

export default BullChartSection;
