import React, { useState } from 'react';
import Background from '../../../img/figures/bull-tile-background.png';
import Backgroundx2 from '../../../img/figures/bull-tile-background@2x.png';
import useInterval from '../../util/hooks/useInterval';
import CircleDownloadButton from './CircleDownloadIcon';
import MarblingMeaterFigure from './MarblingMeaterFigure';
import TriggerOnCenterVisible from './TriggerOnCenterVisible';

const DownloadImageSection = () => {
  const [ visible, setVisible ] = useState(false);
  const [ i, setI ] = useState(0);
  useInterval(() => {
    if (visible) {
      let newIndex = i + 1;
      setI(newIndex);
    }
  }, 1000, [visible]);
  const imageSrc = window.devicePixelRatio >= 2 ? Backgroundx2 : Background;
  const className = `image download-image ${visible ? 'active' : ''}`;
  return (
    <div className={ className } id="download-image-section">
      <TriggerOnCenterVisible onVisible={ () => setVisible(true)} >
        <img className="background" src={ imageSrc } />
        <MarblingMeaterFigure className={ i > 0 ? 'active' : '' }/>
        <CircleDownloadButton className={ `one ${i > 1 ? 'active' : ''}`} />
        <CircleDownloadButton className={ `two ${i > 2 ? 'active' : ''}`} />
        <CircleDownloadButton className={ `three ${i > 3 ? 'active' : ''}`} />
        <CircleDownloadButton className={ `four ${i > 4 ? 'active' : ''}`} />
        <CircleDownloadButton className={ `five ${i > 5 ? 'active' : ''}`} />
      </TriggerOnCenterVisible>
    </div>
  );
};

export default DownloadImageSection;
