/* eslint-disable max-len */
import React from 'react';
import './marbling-meater-figure.scss';
import PropTypes from 'prop-types';

const MarblingMeaterFigure = ({ className }) => (
  <svg className={ `marbling-meater-figure ${className}`} viewBox="0 0 225.672 124.966">
    <g transform="translate(-585 -1549)">
      <g transform="translate(585 1549)">
        <path className="pointer" d="M-956.707,898.047a1.58,1.58,0,0,0-2.18.482l-36.9,57.83a15.916,15.916,0,0,0-2.241,5.84,15.611,15.611,0,0,0,12.843,17.956,15.61,15.61,0,0,0,17.956-12.843L-956,899.637A1.585,1.585,0,0,0-956.707,898.047Zm-25.723,72.961a6.8,6.8,0,0,1-6.8-6.8,6.8,6.8,0,0,1,6.8-6.8,6.8,6.8,0,0,1,6.8,6.8A6.8,6.8,0,0,1-982.43,971.008Z" transform="translate(1095.458 -855.401)"/>
        <path className="available" d="M-850.436,989.8l30.235.065a113.014,113.014,0,0,0-73.379-105.7h0l.014-.037a112.443,112.443,0,0,0-39.472-7.1,112.962,112.962,0,0,0-112.835,112.835h30.226v-7.644h-10.463a92.721,92.721,0,0,1,11.6-37.975l8.165,4.617,3.762-6.656-7.926-4.483a94.192,94.192,0,0,1,28.652-27.459l4.507,7.683,6.593-3.868-4.423-7.538a92.713,92.713,0,0,1,38.839-10l-.049,8.8,7.644.041.049-8.822a92.964,92.964,0,0,1,28.337,5.77l-.029.029a93.263,93.263,0,0,1,10.4,4.652l-4.486,7.462,6.552,3.938,4.562-7.587a94.183,94.183,0,0,1,28.329,27.729l-7.92,4.374,3.7,6.693,8.179-4.517a92.692,92.692,0,0,1,11.132,37.078l-10.416-.018Z" transform="translate(1045.872 -877.028)"/>
        <path className="filled" d="M-893.566,884.125a112.443,112.443,0,0,0-39.472-7.1,112.962,112.962,0,0,0-112.835,112.835h30.226v-7.644h-10.463a92.721,92.721,0,0,1,11.6-37.975l8.165,4.617,3.762-6.656-7.926-4.483a94.192,94.192,0,0,1,28.652-27.459l4.509,7.683,6.593-3.868-4.425-7.538a92.713,92.713,0,0,1,38.839-10l-.047,8.8,7.642.041.049-8.822a92.905,92.905,0,0,1,28.337,5.77Z" transform="translate(1045.872 -877.028)"/>
      </g>
    </g>
  </svg>
);

MarblingMeaterFigure.propTypes = {
  className: PropTypes.string
};

export default MarblingMeaterFigure;
