import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import mongoid from 'mongoid-js';

const TriggerOnCenterVisible = ({ onVisible, children, className }) => {
  const wrapperRef = useRef();
  const id = useRef(mongoid());
  const timeout = useRef(null);
  const visibleTriggered = useRef(false);
  const checkPos = () => {
    const rect = wrapperRef.current.getBoundingClientRect();
    const middle = rect.top + (rect.height / 2);
    const bottom =
      window.innerHeight || document.documentElement.clientHeight;
    if (middle > 0 && middle < bottom) {
      visibleTriggered.current = true;
      window.removeEventListener('scroll', handleScroll);
      onVisible();
    }
  };
  const handleScroll = () => {
    if (!visibleTriggered.current && !timeout.current) {
      timeout.current = setTimeout(() => {
        checkPos();
        timeout.current = null;
      }, 1000);
    }
  };
  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, []);
  return (
    <div ref={ wrapperRef } id={ id.current } className={ className }>
      { children }
    </div>
  );
};

TriggerOnCenterVisible.propTypes = {
  onVisible: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  off: PropTypes.bool,
  className: PropTypes.string
};

export default TriggerOnCenterVisible;
