import { useEffect, useRef } from 'react';

export default function useInterval (callback, delay, deps) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const mergedDeps = deps ? [ delay, ...deps ] : [ delay ];
  useEffect(() => {
    function tick () {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, mergedDeps);
}
