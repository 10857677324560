/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './what-we-measure-donut.scss';
import TriggerOnCenterVisible from './TriggerOnCenterVisible';

const strokeWidth = '1.08450569081046';

const WhatWeMeasureDonut = ({ live, className, children }) => {
  const [ visible, setVisible ] = useState(false);
  const segmentStyle = {
    stroke: '#ffa300',
    transformOrigin: 'center'
  };
  let strokeDash = '0, 100';
  if (live || (visible && window.innerWidth < 800)) {
    segmentStyle.animation = 'what-we-measure-donut';
    segmentStyle.animationDuration = '3s';
    strokeDash = '100, 0';
  }
  return (
    <div className={ `what-we-measure-donut ${className}` }>
      <TriggerOnCenterVisible onVisible={ () => setVisible(true) } >
        <svg viewBox="0 0 34 34" className="measurement-donut">
          <circle className="measurement-donut-hole" cx="17" cy="17" r="15.91549430918954" fill="#fff"></circle>
          <circle className="measurement-donut-ring" cx="17" cy="17" r="15.91549430918954" fill="transparent" strokeWidth={ strokeWidth }></circle>
          <circle style={ segmentStyle } className="measurement-donut-segment" cx="17" cy="17" r="15.91549430918954" fill="transparent" strokeWidth={ strokeWidth } strokeDasharray={ strokeDash } strokeDashoffset="25"></circle>
        </svg>
      </TriggerOnCenterVisible>
      <div className="content">
        { children }
      </div>
    </div>
  );
};

WhatWeMeasureDonut.propTypes = {
  live: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default WhatWeMeasureDonut;
