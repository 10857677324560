/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const CircleDownloadButton = ({ className }) => (
  <svg className={ `circle-download-icon ${className}` } viewBox="0 0 54 54">
    <g transform="translate(-695 -1389)">
      <circle style={{ fill: '#3a3a3a' }} cx="27" cy="27" r="27" transform="translate(695 1389)"/>
      <g transform="translate(711 1402)">
        <path style={{ fill: '#fff' }} d="M-5576.2-1170.362a1.19,1.19,0,0,0-1.193,1.185v5.132h-17.683v-5.132a1.19,1.19,0,0,0-1.194-1.185,1.19,1.19,0,0,0-1.2,1.185v6.318a1.19,1.19,0,0,0,1.2,1.185h20.07a1.19,1.19,0,0,0,1.2-1.185v-6.318A1.19,1.19,0,0,0-5576.2-1170.362Z" transform="translate(5597.463 1184.352)"/>
        <path style={{ fill: '#fff' }} d="M-5574.5-1231.074c.029.028.059.056.09.081l.035.025c.02.015.04.03.06.044l.048.029c.017.01.033.021.052.031l.05.025c.021.008.037.017.057.025s.033.012.05.018l.059.021c.016.005.035.009.053.013s.04.011.06.015.041.007.062.009l.055.008c.04,0,.078.006.118.006s.079,0,.117-.006l.054-.008c.021,0,.041-.005.061-.009a.57.57,0,0,0,.06-.015c.019,0,.037-.008.054-.013l.06-.021.051-.018.056-.025.05-.025c.018-.009.035-.021.051-.031a.5.5,0,0,0,.048-.029c.021-.013.041-.029.059-.043s.024-.016.035-.025c.031-.025.062-.052.09-.081l7.2-7.145a1.18,1.18,0,0,0,0-1.677,1.2,1.2,0,0,0-1.691,0l-5.164,5.124v-11.118a1.189,1.189,0,0,0-1.192-1.185,1.19,1.19,0,0,0-1.2,1.185v11.118l-5.164-5.124a1.2,1.2,0,0,0-1.689,0,1.18,1.18,0,0,0,0,1.677Z" transform="translate(5584.882 1247.076)"/>
      </g>
    </g>
  </svg>
);

CircleDownloadButton.propTypes = {
  className: PropTypes.string
};

export default CircleDownloadButton;
