import React, { useState, useEffect } from 'react';
import DropDownIcon from '../../util/icons/components/DropDownIcon';
import MarblingMeaterLogo from '../../util/logos/MarblingMeaterLogo';
import BullChartSection from './BullChartSection';
import DownloadImageSection from './DownloadImageSection';
import RadarImageSection from './RadarImageSection';
import './static-promo-page.scss';
import TriggerOnCenterVisible from './TriggerOnCenterVisible';
import WhatWeMeasureSection from './WhatWeMeasureSection';
import ReportsImgx2 from '../../../img/figures/meater-report-mockup@2x.png';
import ReportsImg from '../../../img/figures/meater-report-mockup.png';
import IphoneImgx2 from '../../../img/figures/iphone-gbi-mockup@2x.png';
import IphoneImg from '../../../img/figures/iphone-gbi-mockup.png';
import Message from '../../user/signup/components/Message';

const UltrasoundSalesPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);
  const [ techTextActive, setTechTextActive ] = useState(false);
  const [ aiTextActive, setAiTextActive ] = useState(false);
  const [ dataTextActive, setDataTextActive ] = useState(false);
  const [ pdfImageActive, setPDFImageActive ] = useState(false);
  const [ pdfTextActive, setPDFTextActive ] = useState(false);
  const [ qrTextActive, setQRTextActive ] = useState(false);
  const [ iphoneImageActive, setIphoneImageActive ] = useState(false);
  let techTextClass = 'text tech-text';
  if (techTextActive) techTextClass += ' active';
  let aiTextClass = 'text ai-text';
  if (aiTextActive) aiTextClass += ' active';
  let dataTextClass = 'text data-text';
  if (dataTextActive) dataTextClass += ' active';
  let pdfImageClass = 'pdf-image';
  if (pdfImageActive) pdfImageClass += ' active';
  let pdfTextClass = 'chequered-dark pdf-text';
  if (pdfTextActive) pdfTextClass += ' active';
  let qrTextClass = 'chequered-dark qr-text';
  if (qrTextActive) qrTextClass += ' active';
  let iphoneImageClass = 'iphone-image';
  if (iphoneImageActive) iphoneImageClass += ' active';
  return (
    <div className="promo-page">
      <h2 className="sub-title" id="meet">Meet the powerful...</h2>
      <h1 className="title">Ultrasound Eating Quality Predictor</h1>
      <div className="process-grid">
        <RadarImageSection />
        <TriggerOnCenterVisible
          className={ techTextClass }
          onVisible={ () => setTechTextActive(true) }>
          <h4 className="process-title">our technician arrives</h4>
          <p className="process-text">
            Our ultrasound scanning technician will travel to your farm,
            and scan each of your animals using the latest Exago scanning
            equipment. They’ll collect a range of different images and
            measurements for each animal they test.
          </p>
          <a className="next-section-link" href="#bull-chart-image">
            <DropDownIcon />
            <span className="next-text">{ `it doesn't stop there` }</span>
          </a>
        </TriggerOnCenterVisible>
        <BullChartSection />
        <TriggerOnCenterVisible onVisible={ () => setAiTextActive(true) }
          className={ aiTextClass }>
          <h4 className="process-title">
            Experience goes hand in hand with machine learning
          </h4>
          <p className="process-text">
            The images and measurements are then sent off to our partner
            laboratory in the US. With 20+ years of experience and their
            industry leading machine learning algorithms they will identify
            the intra muscular fat content of each animal.
          </p>
          <a className="next-section-link" href="#download-image-section">
            <DropDownIcon />
            <span className="next-text">{ `now the data comes to you` }</span>
          </a>
        </TriggerOnCenterVisible>
        <DownloadImageSection />
        <TriggerOnCenterVisible onVisible={ () => setDataTextActive(true) }
          className={ dataTextClass } >
          <h4 className="process-title">
            The data is presented
          </h4>
          <p className="process-text">
            The results are then sent back to the UK and uploaded into the
            GoodBeef Index cloud infrastructure, where our Marbling Meater
            Report is produced, along with downloadable pdf’s for
            certification and and GoodBeef Index authentication page.
          </p>
          <a className="next-section-link" href="#what-we-measure">
            <DropDownIcon />
            <span className="next-text">{ `what do we measure` }</span>
          </a>
        </TriggerOnCenterVisible>
      </div>
      <WhatWeMeasureSection />
      <div className="chequered-section">
        <TriggerOnCenterVisible onVisible={ () => setPDFImageActive(true) }
          className={ pdfImageClass }>
          <img src={ReportsImg}
            className="chequered-image"
            srcSet={ReportsImg + ' 1x,' + ReportsImgx2 + ' 2x'}/>
        </TriggerOnCenterVisible>
        <TriggerOnCenterVisible onVisible={ () => setPDFTextActive(true) }
          className={ pdfTextClass }>
          <h4 className="chequered-title">PDF Reports</h4>
          <p className="chequered-text">
            { `After your animals have been scanned, we'll produce 
              A pdf session report for you to download. This includes 
              all measurements for each of the animals in your
              scanning session along with charts displaying the
              distribution of your cohort across each metric.
              We'll also produce a Marbling Meater certificate for 
              each animal scanned. Download this certificate 
              and use it to demonstrate distrinctive quality in discusions
              with abbotoirs or other outlets`}
          </p>
        </TriggerOnCenterVisible>
        <TriggerOnCenterVisible onVisible={ () => setQRTextActive(true) }
          className={ qrTextClass }>
          <h4 className="chequered-title">
            QR code integration with GoodBeef Index cloud infrastructure
          </h4>
          <p className="chequered-text">
            { `Both the data and the reports are stored in GoodBeef Index cloud
            infrastructure, making them available from farmer to consumer and
            at any point in the supply chain. Our qr codes make it as simple
            as pointing your camera.`}
          </p>
        </TriggerOnCenterVisible>
        <TriggerOnCenterVisible onVisible={ () => setIphoneImageActive(true) }
          className={ iphoneImageClass }>
          <img src={ ReportsImg }
            className="chequered-image iphone-image"
            srcSet={ `${IphoneImg} 1x, ${IphoneImgx2} 2x`} />
        </TriggerOnCenterVisible>
      </div>
      <div className="plain-text-section">
        <h3 className="plain-text-header">
          See what our ultrasound eating quality predictor can do for you
        </h3>
        <p className="para">
          { `If you have animals that you'd like scanned then get in touch.
          We'd love to hear from you.` }
        </p>
      </div>
      <div className="contact">
        <Message intro="" />
      </div>
      <div className="promo-page-footer">
        <h6 className="footer-title">Ultrasound Scanning is provided by</h6>
        <MarblingMeaterLogo />
      </div>
    </div>
  );
};

export default UltrasoundSalesPage;
