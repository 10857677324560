/* eslint-disable max-len */
import React from 'react';
import './bull-chart-annotations.scss';
import { PropTypes } from 'prop-types';

const BullChartAnnotations = ({ className }) => (
  <svg className={ `bull-chart-annotations ${className}` }
    viewBox="0 0 531 419">
    <g transform="translate(-164 -857)">
      <g className="annotation one">
        <line className="line" x2="27" y2="138" transform="translate(333.5 862.5)"/>
        <circle className="circle" cx="5" cy="5" r="5" transform="translate(328 857)"/>
      </g>
      <g className="annotation two">
        <line className="line" x2="88" y2="80" transform="translate(169.5 937.5)"/>
        <circle className="circle" cx="5" cy="5" r="5" transform="translate(164 932)"/>

      </g>
      <g className="annotation three">
        <line className="line" x1="157" y1="30" transform="translate(533.5 1012.5)"/>
        <circle className="circle" cx="5" cy="5" r="5" transform="translate(685 1037)"/>
      </g>
      <g className="annotation four">
        <circle className="circle" cx="5" cy="5" r="5" transform="translate(561 1123)"/>
        <line className="line" x1="67" y1="63" transform="translate(499.5 1065.5)"/>
      </g>
      <g className="annotation five">
        <circle className="circle" cx="5" cy="5" r="5" transform="translate(479 912)"/>
        <line className="line" x1="20" y2="110" transform="translate(464.5 917.5)"/>
      </g>
      <g className="annotation six">
        <line className="line" x2="37" y2="2" transform="translate(204.5 1044.5)"/>
        <circle className="circle" cx="5" cy="5" r="5" transform="translate(199 1039)"/>
      </g>
      <g className="annotation seven">
        <line className="line" y1="105" x2="81" transform="translate(194.5 1086.5)"/>
        <circle className="circle" cx="5" cy="5" r="5" transform="translate(189 1186)"/>
      </g>
      <g className="annotation eight">
        <line className="line" y1="121" transform="translate(327.5 1065.5)"/>
        <circle className="circle" cx="5" cy="5" r="5" transform="translate(322 1181)"/>
      </g>
      <g className="annotation nine">
        <line className="line" x1="10" y1="206" transform="translate(360.5 1065.5)"/>
        <circle className="circle" cx="5" cy="5" r="5" transform="translate(365 1266)"/>
      </g>
      <g className="annotation ten">
        <line className="line" x1="12" y1="102" transform="translate(412.5 1046.5)"/>
        <circle className="circle" cx="5" cy="5" r="5" transform="translate(419 1143)"/>
      </g>
    </g>
  </svg>
);

BullChartAnnotations.propTypes = {
  className: PropTypes.string
};

export default BullChartAnnotations;
