import React, { useState } from 'react';
import TriggerOnCenterVisible from './TriggerOnCenterVisible';
import WhatWeMeasureDonut from './WhatWeMeasureDonut';
import './what-we-measure-section.scss';
import useInterval from '../../util/hooks/useInterval';

const WhatWeMeasureSection = () => {
  const [ visible, setVisible ] = useState(false);
  const [ animationIndex, setAnimationIndex ] = useState(0);
  const onVisible = () => {
    setVisible(true);
  };
  useInterval(() => {
    if (visible) {
      let newIndex = animationIndex + 1;
      // if (newIndex > 4) newIndex = 0;
      setAnimationIndex(newIndex);
    }
  }, 800);

  return (
    <div className="what-we-measure-section" id="what-we-measure">
      <div className="triangle"></div>
      <TriggerOnCenterVisible onVisible={ onVisible } className="container">
        <h2 className="what-we-measure-title">What we measure</h2>
        <svg className="lines" viewBox="0 0 300 150">
          <line x1="0" y1="0" x2="300" y2="150" stroke="#EBEBEB" />
          <line x1="150" y1="0" x2="150" y2="150" stroke="#EBEBEB" />
          <line x1="300" y1="0" x2="0" y2="150" stroke="#EBEBEB" />
        </svg>
        <WhatWeMeasureDonut className="rump-fat" live={ animationIndex > 0 }>
          <span className="title">Rump Fat Thickness</span>
          <span className="measurement">(mm)</span>
        </WhatWeMeasureDonut>
        <WhatWeMeasureDonut className="rib-fat" live={ animationIndex > 1 }>
          <span className="title">Rib Fat Thickness</span>
          <span className="measurement">(mm)</span>
        </WhatWeMeasureDonut>
        <WhatWeMeasureDonut className="rib-eye" live={ animationIndex > 2 }>
          <span className="title">Rib Eye Area</span>
          <span className="measurement">(cm)</span>
        </WhatWeMeasureDonut>
        <WhatWeMeasureDonut className="imf" live={ animationIndex > 3 }>
          <span className="title">IMF</span>
          <span className="measurement">(%)</span>
        </WhatWeMeasureDonut>
        <WhatWeMeasureDonut className="weight" live={ animationIndex > 4 }>
          <span className="title">Weight</span>
          <span className="measurement">(Kg)</span>
        </WhatWeMeasureDonut>
        <WhatWeMeasureDonut className="balls" live={ animationIndex > 5 }>
          <span className="title">Scrotal Circumference</span>
          <span className="measurement">(cm)</span>
        </WhatWeMeasureDonut>
      </TriggerOnCenterVisible>
    </div>
  );
};

export default WhatWeMeasureSection;
