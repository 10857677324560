import React, { useState } from 'react';
import RadarImageBackground from './RadarImageBackground';
import Radar from './Radar';
import TriggerOnCenterVisible from './TriggerOnCenterVisible';
import useInterval from '../../util/hooks/useInterval';
// import './radar-image-section.scss';

const RadarImageSection = () => {
  const [ running, setRunning ] = useState(false);
  const [ animationIndex, setAnimationIndex ] = useState(0);
  useInterval(() => {
    let newIndex = animationIndex + 1;
    if (newIndex > 4) newIndex = 0;
    setAnimationIndex(newIndex);
  }, 2000);
  const start = () => {
    setRunning(true);
  };
  let leftClass = 'left';
  let rightClass = 'right';
  let topClass = 'top';
  let bottomClass = 'bottom';
  if (running) {
    leftClass = 'left';
    rightClass = 'right';
    topClass = 'top';
    bottomClass = 'bottom';
    switch (animationIndex) {
      case 1:
        rightClass += ' active';
        break;
      case 2:
        leftClass += ' active';
        break;
      case 3:
        topClass += ' active';
        break;
      case 4:
        bottomClass += ' active';
        break;
    }
  }
  const className = running ? 'image radar-image active' :
    'image radar-image';
  return (
    <div className={ className }>
      <TriggerOnCenterVisible className="image-wrapper" onVisible={ start }>
        <RadarImageBackground />
        <Radar className={ leftClass }/>
        <Radar className={ rightClass }/>
        <Radar className={ topClass }/>
        <Radar className={ bottomClass }/>
      </TriggerOnCenterVisible>
    </div>
  );
};

export default RadarImageSection;
