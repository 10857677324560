/* eslint-disable max-len */
import React from 'react';
import './radar.scss';
import PropTypes from 'prop-types';

const Radar = ({ className }) => (
  <svg className={ 'ultrasound-radar-image' + ' ' + className } viewBox="0 0 178.476 246.713">
    <g transform="translate(1333.364 -1115.778) rotate(90)">
      <circle className="circle" cx="11.748" cy="11.748" r="11.748" transform="translate(1227.444 1309.867)"/>
      <path className="band one" d="M1158.244,1230.861a34.8,34.8,0,0,1,58.337-.991" transform="translate(51.457 72.296)"/>
      <path className="band two" d="M1153.513,1228.38a49.4,49.4,0,0,1,80.012,0" transform="translate(45.674 64.263)"/>
      <path className="band three" d="M1148.821,1225.986a64,64,0,0,1,101.035.216" transform="translate(39.939 56.229)"/>
      <path className="band four" d="M1144.146,1223.626a78.61,78.61,0,0,1,121.474-.207" transform="translate(34.224 48.198)"/>
      <path className="band five" d="M1139.48,1221.29a93.212,93.212,0,0,1,142.384,0" transform="translate(28.52 40.164)"/>
      <path className="band six" d="M1134.819,1218.965a107.815,107.815,0,0,1,163.1,0" transform="translate(22.823 32.131)"/>
      <path className="band seven" d="M1130.162,1216.647a122.417,122.417,0,0,1,183.8,0" transform="translate(17.13 24.099)"/>
      <path className="band eight" d="M1125.507,1214.336a137.02,137.02,0,0,1,204.493,0" transform="translate(11.44 16.065)"/>
      <path className="band nine" d="M1120.855,1212.021a151.628,151.628,0,0,1,224.818-.387" transform="translate(5.754 8.033)"/>
      <path className="band ten" d="M1116.148,1209.847a166.234,166.234,0,0,1,245.973-.129"/>
    </g>
  </svg>
);

Radar.propTypes = {
  className: PropTypes.string
};

export default Radar;
